import React from "react";
import "@src/scss/App.scss";
import Seo from "@components/seo";
import Layout from "@components/layout";
import { graphql } from "gatsby";
import ModuleRender from "@components/modules/moduleRender";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContactFormModule from '@components/modules/Forms/ContactForm';
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { cleanP } from '@components/utils/CleanedHtml';
const FormLandingTemplate = ({
    data
}) => {
    // const pageurl = typeof window !== 'undefined' ? window.location.pathname : page?.Slug
    const { glstrapi: { page } } = data
    const breadcrumbs = [
        { label: page?.Pagename, url: `${page?.Slug}` }
    ]
    
    return (
        <Layout popularSearch={page?.Popular_Search} bodyClass="noBannerImage form-landing-page" headerClass="header-dark">
            <Seo
                title={page?.Metadata?.title ? page.Metadata.title : page.Title}
                description={page?.Metadata?.description ? page.Metadata.description : null}
                image={page?.Metadata?.image?.url_sharp?.childImageSharp ? page.Metadata.image.url_sharp.childImageSharp.resize : null}
            />
            <Container fluid="lg">
                <Row>
                    <Col xs={12} xl={7}>
                       {page?.ArticleContent[0]?.FormList === 'GeneralContactForm' &&
                            <ContactFormModule
                                sectionClass="section-contact-landing-form"
                                sectionSpacing="py-40 py-md-64 py-lg-80"
                                removeContainer={true}
                                title={cleanP(page?.BannerImage[0]?.ItemTitle)}
                                text={page?.BannerImage[0]?.ItemText}
                                breadcrumbs={breadcrumbs}
                                breadcrumbsClass="section-breadcrumb-dark"
                                successHeading={page?.ArticleContent[0]?.ThankYouMessageTitle}
                                successText={cleanP(page?.ArticleContent[0]?.ThankYouMessage)}
                            />
                       }
                    </Col>
                </Row>
            </Container>
            <div className="form-landing__image">
                {page?.BannerImage?.[0]?.Image?.url_sharp ?
                    <GatsbyImage
                        image={getImage(page?.BannerImage?.[0]?.Image?.url_sharp)}
                        alt={page?.BannerImage[0]?.Image?.alternativeText ? page?.BannerImage[0]?.Image?.alternativeText : ""}
                        className="form-landing__image__image"
                    />
                :
                    <StaticImage
                        src="../images/no-image.png"
                        quality={100}
                        width={800}
                        height={1200}
                        formats={["auto", "webp"]}
                        alt=""
                        className="form-landing__image__image"
                    />
                }
            </div>
            {page?.BannerImage &&
                <ModuleRender
                    moduleData={page?.BannerImage}
                />
            }
        </Layout>
    );
}

export default FormLandingTemplate

export const query = graphql`
query formLandingQuery($id: ID!) {
    glstrapi {
        page(id: $id, publicationState: LIVE) {
            Pagename
            Title
            Slug
            HeaderTemplate
            PageTemplate
            Popular_Search
            BannerImage {
                ... on GLSTRAPI_ComponentModulesBannerImage {
                    id
                    ItemText
                    ItemTitle
                    Image {
                        alternativeText
                        url
                        url_sharp {
                            childImageSharp {
                                gatsbyImageData(width: 800, height: 1200, quality: 100)
                            }
                        }
                    }
                }
            }                
            ArticleContent {
                ... on GLSTRAPI_ComponentModulesGeneralForm {
                    id
                    Title
                    Content
                    FormList
                    ThankYouMessageTitle
                    ThankYouMessage
                }
            }
            Metadata {
                title
                description
                image {
                    url
                    url_sharp {
                        childImageSharp {
                            resize(width: 1200) {
                                src
                                height
                                width
                            }
                        }
                    }
                }
            }
        }
    }
}
`